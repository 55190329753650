<template>
  <div class="card-header text-end">
          <img src="@/assets/bg.jpg" class="image-bg" alt="image" />
          
          <div class="row header-logo-text mt-3">
            <div class="col-5" style="padding: 0">
              <div class="text-center">
                <img src="@/assets/logo.png" alt="image" class="img-profile" />
              </div>
            </div>
            <div class="col-7 text-start" style="padding: 0">
              <div class="row">
                <span class="title-home mt-3">{{ AppName }}</span>
              </div>
            </div>
          </div>
        </div>

</template>

<script>
import { defineComponent} from "vue";
export default defineComponent({
  name: "Header",
  setup() {
    const AppName = process.env.VUE_APP_NAME;

    return {
      AppName
      
    };
  },
});
</script>

<style scoped>
.card-header {
  position: relative;
  height: 160px;
  min-height: 160px;
  overflow: hidden;
  border-bottom: none !important;
  padding: 0 !important;
}
.image-bg {
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: white;
}
.header-logo-text {
  color: white;
  position: absolute;
  top: 42px;
  width: 100%;
}

.img-profile {
  width: 80px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  background-color: white;
}

.title-home {
  font-size: 20px;
  font-weight: bold;
  background-color: #1f904963;
  color: whitesmoke;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

</style>

